import React from "react"
import { Link } from "gatsby"
import logoUrl from '../../../static/logo/logo.svg'

export default function Footer() {
  return (
    <footer className="text-center bg-primary" style={{padding: '2rem'}}>
      <ul className="nav justify-content-center">
        <li className="nav-item">
          <Link className="nav-link text-light" to="https://www.inmocolonial.com/blog" target="_blank">Blog</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-light" to="/contacto">Contacto</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-light" to="https://www.inmocolonial.com/notas-de-prensa" target="_blank">Prensa</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-light" to="https://www.inmocolonial.com/legal-politica-de-privacidad" target="_blank">Legal</Link>
        </li>
      </ul>
      <ul className="nav justify-content-center mt-4">
        <li className="nav-item">
          <Link className="nav-link text-light" to="https://www.instagram.com/inmocolonial/" target="_blank"><i class="bi bi-instagram"></i></Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-light" to="https://twitter.com/ColonialOficina" target="_blank"><i class="bi bi-twitter"></i></Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-light" to="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQEJHMZtBMU3GQAAAX5YJ8dIo8-5kwdCsBZ2I90LYbbGrU6q1qwxmKjYLp0h4_ABtd5iKixwL9mAYOUiFwoDy3D795jouzKOEFnE4Qe2NteG5VDUvQQ4yi6BYOGep5tuxIITdr8=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Finmobiliaria-colonial%3Ftrk%3Dtyah" target="_blank"><i class="bi bi-linkedin"></i></Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link text-light" to="https://www.youtube.com/c/Oficinas%20colonialprime" target="_blank"><i class="bi bi-youtube"></i></Link>
        </li>
      </ul>
      <ul className="nav justify-content-center border-bottom border-white" style={{marginLeft: '5rem', marginRight: '5rem'}}>
        <li className="nav-item">
          <br />
        </li>
      </ul>
      <div className="nav justify-content-center text-light pt-2 row d-flex align-items-center" style={{marginLeft: '5rem', marginRight: '5rem'}}>
        <div className="col nav-item">
          <p className="nav-link text-light">
            <small>
              <span class="bi bi-telephone-fill"></span>&nbsp;&nbsp;
              <span className="fw-bold">Madrid</span> +34 670 830 069 &nbsp;
            </small>
          </p>
        </div>
        <div className="col nav-item">
          <Link className="navbar-brand" to="/app/home"><img style={{width: '100px'}} src={logoUrl} alt="Logo" /></Link>
        </div>
        <div className="col nav-item">
          <p className="nav-link text-light">
            <small>
              <span class="bi bi-geo-alt-fill"></span>&nbsp;&nbsp;
              <span>Calle Pollensa, 6 Ctra. de la Coruña, km23</span> &nbsp; <br/>
              <span className="fw-bold">Las Rozas de Madrid</span>
            </small>
          </p>
        </div>
      </div>
    </footer>
  )
}