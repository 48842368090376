import React from 'react'
import Footer from '../components/basic/footer'
import NavBar from '../components/basic/nav-bar'
import Breadcrumb from '../components/basic/breadcrumb'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from "gatsby-background-image"
import Seo from '../components/basic/seo'
import ContactForm from '../components/basic/formContact'

export default function Contacto() {
    const data = useStaticQuery(
        graphql`
          query {
            desktop: file(relativePath: { eq: "home_background.jpg" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `
      )
      const imageData = data.desktop.childImageSharp.fluid;
      const config = {
        title: '',
        successMessage: 'Gracias por contactar con nosotros',
        errorMessage: 'Lo sentimos, tenemos algunos problemas.',
        fields:{
          firstName: '',
          lastName: '',
          email: '',
          msg: ''
        },
        fieldsConfig:  [
         { id: 1, label: 'Nombre', fieldName: 'firstName', type: 'text',placeholder:'', isRequired: true , className:'first-name-field'},
         { id: 2, label: 'Apellidos', fieldName: 'lastName', type: 'text', placeholder: '', isRequired: true , className:'last-name-field'},
         { id: 3, label: 'Email', fieldName: 'email', type: 'email', placeholder: '', isRequired: true , className:'email-field'},
         { id: 4, label: 'Mensaje', fieldName: 'msg', type: 'textarea',placeholder:'', isRequired: true , className:'message-field'}
        ]
      };      
  return (
    <div className="container-fluid" style={{ backgroundColor: '#edf0f0', padding: '0'}}>
        <Seo title="contacto" />
        <BackgroundImage
            Tag="section"
            className="container-fluid"
            fluid={imageData}
            style={{ height: '40vh', backgroundPosition: 'bottom' }}
        >
        <NavBar />
        <div className="container">
          <div className="row d-flex align-items-center" style={{ height: '40vh' }}>
            <div className="col">
              <h1 className="text-light">Contacto</h1>
              <Breadcrumb />
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="content">
            <div className="row align-items-center text-secondary">
              <div className='col-4 text-center border-end'>
                <h6 className="card-subtitle mb-2 mt-5 text-dark">Dirección</h6>
                <p>
                  Las Rozas de Madrid España<br/>
                  Calle Pollensa, 6 Crta. de la Coruña, km 23
                </p>
                <h6 className="card-subtitle mb-2 text-dark">Teléfono</h6>
                <p>
                  +34 670 830 069<br/>
                  8:30 - 17:30 horas
                </p>
                <h6 className="card-subtitle mb-2 text-dark">Email</h6>
                <p>
                  cosbim360@jci.com
                </p>
              </div>
              <div className="col-8" style={{padding: '5rem'}}>
                <h5 className="card-title text-primary">Contacta con nosotros</h5>
                <h6>
                  Si tienes alguna duda o consulta, puedes ponerte en contacto con nosotros desde aquí.<br/>
                  Será un placer oferecerte nuestra ayuda.
                </h6>
                <div className='pt-2'>
                  <ContactForm />
                </div>
                <p className='pt-2'>
                  <small style={{fontSize: '12px'}}>De conformidad con el Reglamento 2016/679 General de Protección de Datos le informamos que sus datos son tratados por Inmobiliaria Colonial, SOCIMI, S.A. en calidad de responsable del tratamiento, con la finalidad de atender su solicitud. Puede ejercer sus derechos de acceso, rectificación, supresión, oposición, solicitar la limitación del tratamiento o la portabilidad de sus datos, así como revocar el consentimiento otorgado dirigiéndose a dpo@inmocolonial.com. Asimismo, puede obtener más información acerca de cómo tratamos sus datos en nuestra 
                    <a href='https://www.inmocolonial.com/legal-politica-de-privacidad' target='_blank' without rel="noreferrer" className='text-primary'> Política de privacidad.</a>
                  </small>
                </p>
              </div>
            </div>
          </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}
