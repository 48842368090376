import React, { useState } from "react";

const ContactForm = () => {
  const [status, setStatus] = useState("Enviar");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Enviando...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    let response = await fetch("http://135.181.140.100:15304/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Enviar");
    let result = await response.json();
    alert(result.status);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-2">
        <label className="control-label" htmlFor="name">Nombre:</label>
        <input className="form-control" type="text" id="name" required />
      </div>
      <div className="mb-2">
        <label className="control-label" htmlFor="email">Email:</label>
        <input className="form-control" type="email" id="email" required />
      </div>
      <div className="mb-2">
        <label className="control-label" htmlFor="message">Mensaje:</label>
        <textarea className="form-control" id="message" required />
      </div>
      <button className="mt-2 btn btn-primary" type="submit">{status}</button>
    </form>
  );
};

export default ContactForm;