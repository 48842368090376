import React from 'react'
import { Link } from "gatsby"

export default function Breadcrumb() {
  return (
    <nav>
        <ol className="breadcrumb">
        <li className="breadcrumb-item">
            <Link className="text-decoration-none text-light" to="/">Home</Link>
        </li>
        <li className="breadcrumb-item">
            <Link className="text-decoration-none text-light" to="/madrid">Madrid</Link>
        </li>
        <li className="breadcrumb-item">
            <Link className="text-decoration-none text-light" to="/barcelona">Barcelona</Link>
        </li>
        <li className="breadcrumb-item">
            <Link className="text-decoration-none text-light" to="/utopicus">Utopicus</Link>
        </li>
        </ol>
    </nav>
  )
}
